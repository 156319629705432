<template>
    <v-card :loading="loadingButton">
        <v-card-title class="pb-0 pl-2">
            <v-btn small icon @click="close" class="mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Email notification template
        </v-card-title>
        <v-card-text class="py-0 px-1">
            <div>
                <v-combobox
                    v-model="selectedContacts"
                    :hide-no-data="!search"
                    :items="items"
                    :search-input.sync="search"
                    hide-selected
                    label="Contacts:"
                    multiple
                    small-chips
                    class="mx-4"
                >
                    <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                    >
                        <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            small
                        >
                            <span class="pr-2">
                                {{ item }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                                $delete
                            </v-icon>
                        </v-chip>
                    </template>
                </v-combobox>
            </div>
            <LeadTemplate
                v-if="localSetting.id"
                :setting="localSetting"
                :type="type"
                :language="language"
            />
        </v-card-text>
        <v-card-actions class="pb-4">
            <v-btn
                rounded
                color="warning"
                class="ml-2"
                :loading="loadingButton"
                @click="sendWithoutEmailMessage"
            >
                CONTINUE WITHOUT SENDING EMAIL
            </v-btn>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                class="mr-2"
                :loading="loadingButton"
                @click="saveNotificationMessage"
                >SAVE</v-btn
            >
        </v-card-actions>
        <!--Error Alert-->
        <Errors />
    </v-card>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import Errors from '@/components/Layout/Errors'

export default {
    name: 'NotificationLeadTemplate',
    components: {
        LeadTemplate: () =>
            import(
                '@/components/Settings/Notifications/Leads/LeadTemplate.vue'
            ),
        Errors,
    },
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
        lead: {
            type: Object,
            required: true,
        },
        rejectionReason: {
            type: Object,
            default: () => undefined,
        },
        companySettings: {
            type: Object,
            default: () => ({}),
        },
        loadingButton: {
            type: Boolean,
            default: () => false,
        },
        users: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: () => 'reject',
        },
        estimatorId: {
            type: String,
            default: () => '',
        },
        promiseDate: {
            type: String,
            default: () => '',
        },
    },
    data: () => ({
        localSetting: {},
        items: [],
        search: null,
        selectedContacts: [],
        language: '',
    }),
    async mounted() {
        try {
            this.localSetting = _.cloneDeep(this.setting)
            // retrieve data contacts
            const {
                data: { contacts },
            } = await API.getContactsByClient({
                clientId: this.lead.clientId,
            })
            const {
                data: { user },
            } = await API.getMyInfo()
            this.minDate = new Date().toISOString()

            // set user language
            if (user.language == 'Spanish') {
                this.language = 'Spanish'
            } else if (user.language == 'English') {
                this.language = 'English'
            }

            this.items = contacts.map(contact => contact.email)
            this.selectedContacts = contacts
                .filter(contact => this.lead.contacts.includes(contact.id))
                .map(c => c.email)
            // replace key words
            for (const item of this.localSetting.leads.filter(
                lead => lead.title == this.type
            )) {
                for (const keyWord of item.keyWords) {
                    let replace = { '}': '' }
                    if (keyWord.word == 'projectName') {
                        replace[keyWord.word] = this.lead.name
                    } else if (keyWord.word == 'reason') {
                        replace[keyWord.word] = this.rejectionReason
                            ? this.rejectionReason.text
                            : ''
                    } else if (keyWord.word == 'contactName') {
                        replace[keyWord.word] =
                            this.selectedContacts.length == 0
                                ? ''
                                : contacts.find(
                                      contact =>
                                          contact.email ==
                                          this.selectedContacts[0]
                                  )?.name
                    } else if (keyWord.word == 'accountManagerName') {
                        replace[keyWord.word] = this.users.find(
                            user => user.id == this.lead.userId
                        )?.name
                    } else if (keyWord.word == 'accountManagerEmail') {
                        const user = this.users.find(
                            user => user.id == this.lead.userId
                        )
                        replace[keyWord.word] = user.email
                    } else if (keyWord.word == 'accountManagerPhone') {
                        replace[keyWord.word] = this.users.find(
                            user => user.id == this.lead.userId
                        )?.phone
                    } else if (keyWord.word == 'preconManagerName') {
                        if (this.lead.preconManagerId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.lead.preconManagerId
                            )?.name
                        }
                    } else if (keyWord.word == 'preconManagerEmail') {
                        if (this.lead.preconManagerId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.lead.preconManagerId
                            )?.email
                        }
                    } else if (keyWord.word == 'preconManagerPhone') {
                        if (this.lead.preconManagerId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.lead.preconManagerId
                            )?.phone
                        }
                    } else if (keyWord.word == 'estimatorName') {
                        if (this.estimatorId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.estimatorId
                            )?.name
                        }
                    } else if (keyWord.word == 'estimatorEmail') {
                        if (this.estimatorId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.estimatorId
                            )?.email
                        }
                    } else if (keyWord.word == 'estimatorPhone') {
                        if (this.estimatorId) {
                            replace[keyWord.word] = this.users.find(
                                user => user.id == this.estimatorId
                            )?.phone
                        }
                    } else if (keyWord.word == 'promiseDate') {
                        replace[keyWord.word] = this.promiseDate
                    }

                    // replace all keys of templates
                    item.template = item.template.map(template => {
                        const regex = new RegExp(
                            Object.keys(replace).join('|'),
                            'g'
                        )
                        template = template.replace(
                            regex,
                            value => replace[value]
                        )
                        return template.replaceAll('${', '')
                    })
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        sendWithoutEmailMessage() {
            this.$emit('saveNotificationMessage', {
                contacts: this.selectedContacts,
                template: '',
            })
        },
        saveNotificationMessage() {
            let newTemplate = this.localSetting.leads.find(
                lead => lead.title == this.type
            )?.template
            // filter the user language template
            if (this.language == 'English') {
                newTemplate = newTemplate[0]
            } else if (this.language == 'Spanish') {
                newTemplate = newTemplate[1]
            }
            this.$emit('saveNotificationMessage', {
                contacts: this.selectedContacts,
                template: newTemplate,
            })
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
